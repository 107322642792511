<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import CalculatorFormatted from '@/components/ui/Calculator/CalculatorFormatted.vue'
import useCurrencyFilter from '@/components/composables/CurrencyFilter.js'
import StringField from '@/components/ui/Calculator/StringField.vue'
import Choice from '@/components/ui/Choice/Choice.vue'
import QrCodeWithLogo from 'qrcode-with-logos'
import BolsterIcon from '@/assets/logos/Bolster_Icon_Fill_Yellow.png'

const cf = useCurrencyFilter()

const $store = useStore()
const searchPhrase = ref('')
const status = ref('a')
const loading = ref(0)

const formatList = (set) =>
  set.map((ls) => {
    ls.countClients = c.format(ls.lead_source_count_clients, 'number', 0)
    ls.countBooked = c.format(ls.lead_source_count_quotes_booked, 'number', 0)
    ls.conversion = c.format(
      _.divide(ls.lead_source_count_quotes_booked, ls.lead_source_count_clients) * 100,
      'currency',
      0
    )
    ls.profit =
      c.toNum(ls.lead_source_sum_quotes_booked) *
      c.markupToMargin($store.state.session.company.company_default_markup || 1.3)
    ls.roi = c.format(
      (c.divide(ls.profit, c.toNum(ls.lead_source_cost_net)) || 1) * 100 - 100,
      'currency'
    )

    ls.sumBooked =
      ls.lead_source_sum_quotes_booked < 1000000
        ? `${cf.$(ls.lead_source_sum_quotes_booked / 1000, 0)}k`
        : `${cf.$(ls.lead_source_sum_quotes_booked / 1000000, 1)}m`

    return ls
  })

const examples = ref(
  formatList([
    {
      lead_source_name: 'Google AdWords',
      lead_source_cost_net: 24000,
      lead_source_count_clients: 50,
      lead_source_count_quotes_booked: 20,
      lead_source_sum_quotes_booked: 20 * 50000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: "Spring '25 Flyer Campaign",
      lead_source_cost_net: 5000,
      lead_source_count_clients: 22,
      lead_source_count_quotes_booked: 6,
      lead_source_sum_quotes_booked: 6 * 5000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'Bing Ads',
      lead_source_cost_net: 80000,
      lead_source_count_clients: 200,
      lead_source_count_quotes_booked: 80,
      lead_source_sum_quotes_booked: 80 * 50000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'X Ads',
      lead_source_cost_net: 6000,
      lead_source_count_clients: 14,
      lead_source_count_quotes_booked: 10,
      lead_source_sum_quotes_booked: 10 * 30000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'Facebook Ads',
      lead_source_cost_net: 6000,
      lead_source_count_clients: 14,
      lead_source_count_quotes_booked: 10,
      lead_source_sum_quotes_booked: 10 * 30000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'Community Newspaper Ad',
      lead_source_cost_net: 1000,
      lead_source_count_clients: 2,
      lead_source_count_quotes_booked: 1,
      lead_source_sum_quotes_booked: 1 * 1000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'June flyer mail-out',
      lead_source_cost_net: 2500,
      lead_source_count_clients: 10,
      lead_source_count_quotes_booked: 4,
      lead_source_sum_quotes_booked: 4 * 9000,
      lead_source_status: 'a',
      example: true
    },
    {
      lead_source_name: 'Referrals',
      lead_source_cost_net: 0,
      lead_source_count_clients: 15,
      lead_source_count_quotes_booked: 10,
      lead_source_sum_quotes_booked: 10 * 100000,
      lead_source_status: 'a',
      example: true
    }
  ])
)
const list = ref([])

const search = async () => {
  loading.value = 1
  const { set = [] } = await $store.dispatch('LeadSource/filter', {
    searchPhrase: searchPhrase.value ?? null,
    filters: {
      lead_source_status: status
    }
  })

  list.value = [...formatList(set)]
  loading.value = 0
}

const saveCampaign = async (index, field, reload = false) => {
  loading.value = 1
  await $store.dispatch('LeadSource/partialUpdate', {
    selected: [
      {
        lead_source_id: list.value[0].lead_source_id,
        [field]: list.value[0][field],
        type: 'lead_source'
      }
    ]
  })
  loading.value = 0

  if (reload) {
    search()
  }
}

const implementExample = async (ls) => {
  loading.value = 1

  const { object } = await $store.dispatch('LeadSource/save', {
    object: ls
  })
  list.value = [...list.value, ...formatList([object])]
  loading.value = 0
}

onMounted(() => {
  search()
})

const getLink = (lsid) =>
  `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}/form/${$store.state.session.company.company_id}?sid=${lsid}`
const copyLink = (lsid) => $store.dispatch('copyToClipboard', getLink(lsid))
const downloadQrCode = (lsid) => {
  // const img = document.createElement('img')
  new QrCodeWithLogo({
    content: getLink(lsid),
    width: 500,
    logo: {
      src: BolsterIcon
    },
    download(downloadFn) {
      // you can choose when to start download by downloadFn...
      downloadFn()
    }
  })
  //
  // const link = document.createElement('a')
  // console.log(getLink(), img, img.src);
  // link.href = img.src
  //
  // // Set the download attribute with a default filename
  // link.download = 'LeadFormQRCode.jpg'
  //
  // // Trigger the download by clicking the link
  // link.click()
}

const sectionContainer =
  'leading-tight basis-40 user-select-none cursor-pointer hover:bg-surface-100 border-transparent border-2 p-2 rounded-lg'
const sectionLabel = 'flex flex-col text-sm font-medium text-surface-500'
const sectionValue = '!font-medium tabular-nums text-xl'
const showExamples = ref(false)
</script>

<template>
  <Page title="Campaign trackers" icon="flag-swallowtail" scrollable>
    <div class="relative w-full h-fit">
      <div class="w-full flex flex-col justify-start items-stretch gap-4">
        <div class="mt-6 font-medium text-xl">Your active campaigns</div>

        <template v-if="!list.length">
          <Automation :closable="false">
            You have no active campaigns yet! Create one from scratch, or choose a starting point
            from the list below. When you choose it, it will set all the counts to zero and you can
            change the name.
          </Automation>
        </template>
        <template v-for="(ls, index) in list" :key="ls.lead_source_id">
          <div class="flex justify-start items-center w-full gap-2 border rounded-sm p-4">
            <div class="flex flex-col basis-1/5 p-2">
              <div class="flex items-center whitespace-nowrap gap-2">
                <font-awesome-icon icon="flag-swallowtail" />
                <StringField
                  v-model="ls.lead_source_name"
                  @blur="saveCampaign(index, 'lead_source_name')"
                />
              </div>
              <div
                class="flex flex-col items-start whitespace-nowrap gap-1"
                :class="{
                  'opacity-40': ls.example
                }"
              >
                <Btn size="sm" severity="tertiary" :action="() => copyLink(ls.lead_source_id)">
                  <font-awesome-icon icon="copy" /> Copy lead-form link
                </Btn>
                <Btn
                  size="sm"
                  severity="tertiary"
                  :action="() => downloadQrCode(ls.lead_source_id)"
                >
                  <font-awesome-icon icon="qrcode" /> Download lead-form QR code
                </Btn>
              </div>
            </div>

            <div
              v-tooltip="
                'Set the total costs associated with this campaign. Update it as you spend more to keep track of ROI'
              "
              :class="sectionContainer"
            >
              <span :class="sectionLabel">Costs</span>
              <div :class="sectionValue">
                <CalculatorFormatted
                  @blur="saveCampaign(index, 'lead_source_cost_net')"
                  format="$"
                  v-model="ls.lead_source_cost_net"
                />
              </div>
            </div>

            <div
              v-tooltip="
                'This is how many leads/clients you\'ve attracted from this campaign. This includes all clients, even ones who booked, haven\'t booked yet or even declined'
              "
              :class="sectionContainer"
            >
              <span :class="sectionLabel">New clients</span>
              <div :class="sectionValue">
                {{ ls.countClients }}
              </div>
            </div>

            <div
              v-tooltip="
                `This is the percentage of new clients that actually turn into booked projects, from this campaign.`
              "
              :class="sectionContainer"
            >
              <span :class="sectionLabel">Conversion %</span>
              <div :class="sectionValue">{{ ls.conversion }}%</div>
            </div>

            <div
              v-tooltip="
                `This is the number and sum of the value of the projects that you sourced from this campaign.`
              "
              :class="sectionContainer"
            >
              <span :class="sectionLabel">New projects</span>
              <div :class="sectionValue">{{ ls.countBooked }} • {{ ls.sumBooked }}</div>
            </div>

            <div
              v-tooltip="
                'ROI: this is your return on investment. You want this to be positive and as high as possible! A negative ROI means you lost money on this campaign -- for now. A positive value means you\'ve made back your initial investment, PLUS more.'
              "
              :class="sectionContainer"
            >
              <span :class="sectionLabel">ROI</span>
              <div
                :class="[
                  sectionValue,
                  {
                    'text-deep-red-500': ls.roi < 0,
                    'text-orange-500': ls.roi >= 0 && ls.roi < 10,
                    'text-green-500': ls.roi > 10
                  }
                ]"
              >
                {{ ls.roi }}%
              </div>
            </div>

            <div>
              <Choice
                :allowDeselect="false"
                @choice="saveCampaign(index, 'lead_source_status', true)"
                v-model="ls.lead_source_status"
                :choices="[
                  {
                    value: 'i',
                    title: 'Inactive campaign',
                    desc: 'When this campaign is done, deactivate it so it is hidden from options and this list. The links generated from it will still work just fine in case you get any stragglers!'
                  },
                  {
                    value: 'a',
                    title: 'Active campaign',
                    desc: 'Keep this campaign active if you want to keep tracking it and keep it as an option when adding a new lead manually.'
                  }
                ]"
              >
                <template #default="{ text, choose }">
                  <Btn size="sm" severity="tertiary" :action="choose">{{ text }}</Btn>
                </template>
              </Choice>
            </div>
          </div>
        </template>

        <div class="flex justify-start items-center">
          <Btn
            size="lg"
            severity="tertiary"
            @click="implementExample({ lead_source_name: 'My new lead source' })"
          >
            <font-awesome-icon icon="fas fa-plus" />
            Create new marketing campaign / lead source
          </Btn>
          <Btn link v-if="!(!list.length || showExamples)" @click="showExamples = true"
            >See some examples...</Btn
          >
        </div>

        <template v-if="!loading && (!list.length || showExamples)">
          <div class="mt-6 font-medium text-xl">Examples</div>

          <template v-for="ls in examples" :key="ls.lead_source_name">
            <div
              class="relative flex justify-start items-center w-full gap-2 border rounded-sm p-4 hover:ring-1 hover:ring-pitch-black hover:border-pitch-black opacity-60 hover:opacity-100"
            >
              <div class="flex flex-col basis-1/5 p-2 pointer-events-none">
                <div class="flex items-center whitespace-nowrap gap-2">
                  <font-awesome-icon icon="flag-swallowtail" />
                  <StringField v-model="ls.lead_source_name" />
                </div>
                <div
                  class="flex flex-col items-start whitespace-nowrap gap-1"
                  :class="{
                    'opacity-40': ls.example
                  }"
                >
                  <Btn size="sm" severity="tertiary">
                    <font-awesome-icon icon="copy" /> Copy lead form link
                  </Btn>
                  <Btn size="sm" severity="tertiary">
                    <font-awesome-icon icon="arrow-down-to-bracket" /> Download lead form QR code
                  </Btn>
                </div>
              </div>

              <div
                v-tooltip="
                  'Set the total costs associated with this campaign. Update it as you spend more to keep track of ROI'
                "
                class="pointer-events-none"
                :class="sectionContainer"
              >
                <span :class="sectionLabel">Costs</span>
                <div :class="sectionValue">
                  <CalculatorFormatted format="$" v-model="ls.lead_source_cost_net" />
                </div>
              </div>

              <div
                v-tooltip="
                  'This is how many leads/clients you\'ve attracted from this campaign. This includes all clients, even ones who booked, haven\'t booked yet or even declined'
                "
                class="pointer-events-none"
                :class="sectionContainer"
              >
                <span :class="sectionLabel">New clients</span>
                <div :class="sectionValue">
                  {{ ls.countClients }}
                </div>
              </div>

              <div
                v-tooltip="
                  'This is how many leads/clients you\'ve attracted from this campaign. This includes all clients, even ones who booked, haven\'t booked yet or even declined'
                "
                class="pointer-events-none"
                :class="sectionContainer"
              >
                <span :class="sectionLabel">Conversion %</span>
                <div :class="sectionValue">{{ ls.conversion }}%</div>
              </div>

              <div
                v-tooltip="
                  'This is how many leads/clients you\'ve attracted from this campaign. This includes all clients, even ones who booked, haven\'t booked yet or even declined'
                "
                class="pointer-events-none"
                :class="sectionContainer"
              >
                <span :class="sectionLabel">New projects</span>
                <div :class="sectionValue">{{ ls.countBooked }} • {{ ls.sumBooked }}</div>
              </div>

              <div
                v-tooltip="
                  'ROI: this is your return on investment. You want this to be positive and as high as possible! A negative ROI means you lost money on this campaign -- for now. A positive value means you\'ve made back your initial investment, PLUS more.'
                "
                class="pointer-events-none"
                :class="sectionContainer"
              >
                <span :class="sectionLabel">ROI</span>
                <div
                  :class="[
                    sectionValue,
                    {
                      'text-deep-red-500': ls.roi < 0,
                      'text-orange-500': ls.roi >= 0 && ls.roi < 10,
                      'text-green-500': ls.roi > 10
                    }
                  ]"
                >
                  {{ ls.roi }}%
                </div>
              </div>

              <div class="pointer-events-none">
                <Choice
                  v-model="ls.lead_source_status"
                  :choices="[
                    {
                      value: 'i',
                      title: 'Deactivate this campaign',
                      desc: 'When this campaign is done, deactivate it so it is hidden from options and this list. The links generated from it will still work just fine in case you get any stragglers!'
                    },
                    {
                      value: 'a',
                      title: 'Active campaign',
                      desc: 'Keep this campaign active if you want to keep tracking it and keep it as an option when adding a new lead manually.'
                    }
                  ]"
                >
                  <template #default="{ text }">
                    <Btn size="sm" severity="tertiary">{{ text }}</Btn>
                  </template>
                </Choice>
              </div>

              <div class="absolute inset-0 hover:opacity-100 opacity-0">
                <div class="absolute inset-2 bg-white/80 flex flex-col justify-center items-center">
                  <div class="font-medium">This is only an example for now...</div>
                  <Btn :action="() => implementExample(ls)" size="xl" severity="bolster">
                    <font-awesome-icon icon="fas fa-plus" />
                    Implement this example
                  </Btn>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <Loader :loading="loading" />
    </div>
  </Page>
</template>
<style lang="scss" rel="stylesheet/scss" scoped></style>
