import { useStore } from 'vuex'

export default function useCurrencyFilter() {
  const store = useStore()

  // methods //
  const formatNumber = (val, dec = null) => {
    if (!store || !store.state.session.user) {
      return c.format(val, 'number')
    }

    const sigdec = c.getSignificantDecimals(val, dec)
    const minimumFractionDigits = sigdec
    const maximumFractionDigits = sigdec
    return c.toNum(val).toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits
    })
  }

  const formatCurrency = (val, dec = 2) => {
    if (!store || !store.state.session.user || !store.state.session.company) {
      return c.format(val, 'currency')
    }

    return c.toNum(val).toLocaleString(undefined, {
      minimumFractionDigits: dec,
      maximumFractionDigits: dec
    })
  }

  const formatCurrencySymbol = (val, dec = 2, code = null) => {
    if (!store || !store.state.session.user || !store.state.session.company) {
      return c.format(val, 'currency')
    }

    const currency =
      code ||
      (store.state.session.company && store.state.session.company.currency_iso) ||
      (store.state.session.user && store.state.session.user.currency_iso) ||
      'USD'
    return c.toNum(val).toLocaleString(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: dec,
      maximumFractionDigits: dec,
      currencyDisplay: 'symbol'
    })
  }

  const deformatNumber = (val) => {
    if (!store || !store.state.session.user) {
      return c.format(val, 'number')
    }

    const thou = store.state.session.user.localization_thousands_separator
    const decs = store.state.session.user.localization_decimal_separator

    const noalpha = String(val).replace(/[^0-9,.]/, '')
    const computerdec = noalpha.replace(thou, '').replace(decs, '.')

    return +computerdec
  }

  const deformatCurrency = (val) => deformatNumber(val)

  // filters (AKA methods) //
  const currency = (val, dec = 2) => formatCurrency(val, dec)

  const currencySymbol = (val, dec = 2, code = null) => formatCurrencySymbol(val, dec, code)

  const $ = (val, dec = 2, code = null) => formatCurrencySymbol(val, dec, code)

  const number = (val, dec = null) => formatNumber(val, dec)

  return {
    // methods //
    formatNumber,
    formatCurrency,
    formatCurrencySymbol,
    deformatNumber,
    deformatCurrency,

    // filters //
    currency,
    currencySymbol,
    $,
    number
  }
}
